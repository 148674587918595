.action-monitoring {
  position: fixed;
  right: 32px;
  bottom: 33px;
  display: flex;
  gap: 1rem;
}
.icon-modal {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: blue;
}

.icon-modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.button-container {
  display: flex;
}
.icon-edit {
  margin-left: 5px;
  color: white;
}

.icon-edit:hover {
  cursor: pointer;
  color: tomato;
}
/* Modal */
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-form {
  /* width: 300px; */
  background-color: white;
  padding: 12px;
  position: relative;
  border-radius: 7px;
}

.container-form form {
  display: flex;
  flex-direction: column;
}

.container-form span {
  display: block;
  text-align: center;
}

.icon-close-modal {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -12px;
  color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-close-modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.input-calon,
.input-tanggal {
  height: 36px;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 18px;
}

.input-gambar-calon {
  margin-bottom: 14px;
}

.btn-cari {
  height: 36px;
  background-color: rgb(2, 83, 125);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
}

.btn-cari:hover {
  background-color: rgb(4, 117, 174);
  cursor: pointer;
  color: rgb(220, 220, 220);
}

/* RESET MODAL */
.modal-container .isTanggal {
  width: 350px;
}

.input-tanggal {
  margin-bottom: 6px;
}

/* Date Picker Input*/

.date-picker {
  display: flex;
  align-items: center;
}

.date-picker-input {
  width: 164px;
}
