.modal-link-container {
  position:'absolute' ;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  max-height: 600;
  display: block;
}
.modal-link-container-form {
  padding: 1rem 3rem;
  border-bottom: 1px solid darkgrey;
  color: #fff;
}
.modal-content {
  padding: 1rem 3rem;
  overflow: scroll;
}
.modal-content > p {
  color: #fff;
}

/* Modal subscribe specific style */

.subscribe-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe-container-form {
  background-color: transparent;
  padding: 12px;
  position: relative;
  border-radius: 7px;
  border: 1px solid rgb(109, 109, 109);
}

.subscribe-container-form form {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
}

.subscribe-icon-close-modal {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -12px;
  color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe-icon-close-modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.subscribe-circle {
  width: 20px;
  height: 20px;
  background-color: #0080B3;
  border-radius: 20px;
  margin-right: .5rem;
}

.subscribe-title-container {
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.subscribe-title-container span {
  font-size: 2rem;
  font-weight: 600;
}

.subscribe-title-container span:last-child {
  color: #FCE35A;
}

.group-control {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.group-control input::-webkit-input-placeholder {
  color: #000;
} 

.input-subscriber {
  width: 49%;
  height: 40px;
  border: 2px solid #999999;
}

.sel-option-subscriber {
  width: 49%;
  border-radius: 5px;
  border: 2px solid #999999;
  margin-left: 3px;
}

.subs-btn-container {
  text-align: center;
}

.btn-go {
  height: 36px;
  width: 100px;
  background-color: #FF0100;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  margin: 1rem;
  cursor: pointer;
}


.input-calon,
.input-tanggal {
  height: 36px;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 18px;
}

.input-gambar-calon {
  margin-bottom: 14px;
}

@media only screen and (max-width: 768px) {
  .subscribe-container-form {
    width: 90vw;
    height: 90vh;
    overflow-y: scroll;
  }
  .subscribe-icon-close-modal {
    right: 0;
    top: 0;
  }
  .subscribe-package { 
    text-align: center;
  }
  .subscribe-package img {
    height: 85vh;
  }
  .subscribe-title-container {
    align-items: flex-start;
  }
  .subscribe-circle {
    width: 20px;
    height: 15px;
    background-color: #0080B3;
    border-radius: 40px;
    margin-right: .5rem;
    margin-top: 5px;
  }
  .subscribe-title-container span {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .group-control {
    flex-direction: column;
    padding: 0;
  }
  .input-subscriber {
    width: auto;
    margin: .5rem .2rem 0 .2rem;
  }
  .sel-option-subscriber {
    width: auto;
    margin-top: .5rem;
    height: 40px;
  }
  .subscribe-container-form form {
    padding: .5rem;
  }
}