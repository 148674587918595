.navbar-person {
  height: 70px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navbar-person h2 {
  font-size: 36px;
  font-weight: 600;
  color: rgb(224, 223, 223);
  white-space: nowrap;
  letter-spacing: 5px;
}

.suar-logo img {
  margin-top: 3rem;
}

.navbar-person .icon-login {
  color: white;
  position: absolute;
  right: 20px;
  top: 22px;
  display: flex;
}

.icon-login span {
  margin-right: 5px;
  color: rgb(224, 223, 223);
  cursor: pointer;
}

.icon-login span:hover {
  cursor: pointer;
  color: tomato;
}

.linear-progress-nav {
  width: 100%;
}

.btn-subscribe {
  padding: 0 5px;
}
