.wrapperLogin {
  width: 100vw;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  border: 1px solid white;
  background-color: rgba(240, 240, 240, 0.2);
  border-radius: 15px;
  width: 20rem;
  padding: 14px;
  color: rgb(228, 226, 226);
}

.container-login .iconUser {
  width: 100px;
  height: 100px;
  margin: 10px auto;
}

.iconUser img {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 50%;
}

.inputGroup {
  margin-bottom: 10px;
}

.inputGroup label {
  font-size: 20px;
}

.inputGroup .inputWrapper {
  width: 96%;
  height: 27px;
  /* background-color: rgb(202, 202, 202); */
  color: black;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.inputWrapper input {
  border: none;
  border-bottom: 1px solid white;
  width: 100%;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  /* background-color: rgb(202, 202, 202); */
  background: none;
  outline: none;
  color: white;
}

.inputWrapper input:focus {
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 9px;
  color: white;
}

.container-login button {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  margin-top: 20px;
  color: rgb(230, 230, 230);
  background-color: rgb(2, 83, 125);
  border: 1px solid white;
  font-size: 18px;
}
.container-login button:hover {
  cursor: pointer;
  background-color: rgb(1, 56, 83);
}

form {
  margin-bottom: 12px;
}

.container-login a {
  color: white;
}
.container-login a:hover {
  color: tomato;
}
