.maintenance-container {
  background-image: url('../../../src/images/maintenance-bg.png');
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  padding: 5rem 0;
  color: white;
  justify-content: space-around;
}

.maintenance-content {
  text-align: center;
  margin: 0 1rem;
}
.maintenance-description {
  margin: 2rem 0;
}
.maintenance-description span {
  display: block;
  font-size: 1.5rem;
}
.maintenance-description span:last-child {
  padding-top: 2rem;
}

.maintenance-sosmed img {
  margin: 1rem 1.5rem;
}

.maintenance-content-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .maintenance-content-image {
    display: none;
  }
}