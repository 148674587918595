.container-person {
  width: 100vw;
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-stardate {
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 28px;
  top: 25px;
}
.navbar-stardate h3 {
  font-size: 16px;
  font-weight: 100;
  color: rgb(224, 223, 223);
  margin: 0 5px;
  width: 170px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 27px;
  margin: auto 3px;
  border: none;
  background: transparent;
  color: rgb(224, 223, 223);
  outline: none;
  font-weight: bolder;
  font-size: 13.5px;
  cursor: pointer;
}

.navbar-stardate span {
  font-size: 16px;
  font-weight: 500;
  color: rgb(224, 223, 223);
  margin-left: -2px;
  margin-right: 5px;
}
.navbar-stardate h4 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(224, 223, 223);
}

.wrapper-person {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 76vw;
  margin: 23px auto;
}

.card-person {
  width: 335px;
  height: 580px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.title-person {
  height: 90px;
  width: 90px;
  border-radius: 10px;
  margin-bottom: 5px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
}

.title-person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.wrapper-name {
  height: 4%;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: uppercase;
}

.wrapper-name h4 {
  color: rgb(218, 216, 216);
  font-size: 17px;
  font-weight: 600;
}

.contents-person {
  height: 68%;
  width: 90%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.social-media {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.icon-sosial {
  flex: 1;
}

.social-media .icon-sosial:hover,
.active {
  cursor: pointer;
  color: tomato;
}

.numfound-wrapper {
  height: 7%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.numfound-wrapper .numfound {
  flex: 1;
  font-weight: 600;
  font-size: 20px;
  font-family: "IBM Plex Sans";
}

.content-article {
  height: 70%;
  width: 99%;
  overflow-y: auto;
}

.content-article .article {
  color: rgb(224, 223, 223);
  border-bottom: 1px solid white;
  padding: 5px;
  font-family: "Roboto";
  font-weight: 300;
  margin-bottom: 7px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s ease;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content-article .article:hover {
  cursor: pointer;
  color: tomato;
}

a {
  text-decoration: none;
  color: rgb(21, 31, 165);
}
a:hover {
  color: rgb(230, 230, 230);
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(243, 243, 243, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 35, 53);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 61, 92);
}

/* icon remove di card calon member */
.icon-remove {
  width: 24px;
  height: 24px;
  background-color: tomato;
  color: white;
  position: absolute;
  bottom: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-edit {
  width: 24px;
  height: 24px;
  background-color: blue;
  color: white;
  position: absolute;
  top: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-remove:hover {
  background-color: rgb(251, 61, 61);
  color: rgb(250, 249, 249);
  cursor: pointer;
}

::placeholder {
  color: rgb(226, 226, 226);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(240, 240, 240);
}

/* INPUT DATE PICKER */
input {
  width: 100%;
  height: 27px;
  margin: auto 3px;
  border-radius: 7px;
}

/* Loading Circle */
.loading-circle {
  width: 100vw;
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* WIDGET */
#widget {
  padding: 0 16px;
  margin: 0 auto;
  width: 15%;
  /* width: 24vw; */
}

iframe {
  border-radius: 10px !important;
  height: 375px !important;
}

/* Density Word */
.density-wrapper {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.title-density {
  white-space: wrap;
}

/* Mobile */
@media screen and (max-width: 768px) {
  /* .icon-login span {
    display: none;
  } */
  .navbar-person h2 {
    font-size: 29px;
  }
  .navbar-person .icon-login {
    right: 0;
  }
  .navbar-stardate h3 {
    width: 128px;
  }
  .navbar-stardate h4 {
    font-size: 8px;
  }
}
