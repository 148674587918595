.container-footer {
  width: 100%;
  height: 75px;
}

.wrapper-footer {
  width: 82vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-left: 25px;
}

.tradmark {
  color: rgb(224, 223, 223);
  position: fixed;
  bottom: 1rem;
}

.tradmark span {
  font-size: 12px;
}

.data-stream {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(224, 223, 223);
  white-space: nowrap;
}